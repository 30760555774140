<template>
  <div>
    <!-- ======= MAIN ======= -->
    <main id="main">
      <!-- ======= INTRO YAZISI ======= -->
      <section class="intro-single">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">İletişim</h1>
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-box d-flex justify-content-lg-end"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">Anasayfa</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    İletişim
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <!-- ======= INTRO YAZISI ======= -->

      <!-- ======= İLETİŞİM ======= -->
      <section class="contact">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 section-t8 mb-5">
              <div class="row">
                <div class="col-md-7">
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <div class="form-group">
                        <input
                          v-model="adii"
                          type="text"
                          name="name"
                          class="form-control form-control-lg form-control-a"
                          placeholder="Ad & Soyad"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="form-group">
                        <input
                          v-model="gidecekmail"
                          name="email"
                          type="email"
                          class="form-control form-control-lg form-control-a"
                          placeholder="E-Posta"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class="form-group">
                        <input
                          v-model="konu"
                          type="text"
                          name="subject"
                          class="form-control form-control-lg form-control-a"
                          placeholder="Konu"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea
                          v-model="mesaj"
                          name="message"
                          class="form-control"
                          cols="45"
                          rows="8"
                          placeholder="Mesaj"
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-12 my-3">
                      <div class="mb-3">
                        <!-- <div class="loading">Yükleniyor</div> -->
                        <div class="error-message"></div>
                        <div v-if="mailKontrol" class="sent-message">
                          Mesajınız gönderildi. Teşekkür Ederiz!
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 text-center">
                      <button
                        v-if="!mailKontrol"
                        @click="yolla()"
                        type="submit"
                        class="btn btn-b"
                      >
                        Gönder
                      </button>
                      <button
                        v-else
                        type="submit"
                        class="btn btn-b"
                        disabled="disabled"
                      >
                        Gönder
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 section-md-t3">
                  <div class="icon-box section-b2">
                    <div class="icon-box-icon">
                      <span class="bi bi-envelope"></span>
                    </div>
                    <div class="icon-box-content table-cell">
                      <div class="icon-box-title">
                        <h4 class="icon-title">İletişim</h4>
                      </div>
                      <div class="icon-box-content">
                        <p class="mb-1">
                          E-Posta.
                          <span class="color-a">{{
                            Belediyebilgiler.mail
                          }}</span>
                        </p>
                        <p class="mb-1">
                          Telefon.
                          <span class="color-a">{{
                            Belediyebilgiler.iletisim
                          }}</span>
                        </p>
                        <p class="mb-1">
                          Fax.
                          <span class="color-a">{{
                            Belediyebilgiler.faxnumarasi
                          }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="icon-box section-b2">
                    <div class="icon-box-icon">
                      <span class="bi bi-geo-alt"></span>
                    </div>
                    <div class="icon-box-content table-cell">
                      <div class="icon-box-title">
                        <h4 class="icon-title">Ulaşım</h4>
                      </div>
                      <div class="icon-box-content">
                        <p class="mb-1">
                          {{ Belediyebilgiler.belediyeadresi }}
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="icon-box">
                    <div class="icon-box-icon">
                      <span class="bi bi-share"></span>
                    </div>
                    <div class="icon-box-content table-cell">
                      <div class="icon-box-title">
                        <h4 class="icon-title">Sosyal Medya</h4>
                      </div>
                      <div class="mayor-social center margin-top-50">
                        <a :href="Belediyebilgiler.face" target="_blank"
                          ><svg
                            class="svg-inline--fa fa-facebook-f fa-w-9"
                            aria-hidden="true"
                            data-prefix="fab"
                            data-icon="facebook-f"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 264 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"
                            ></path></svg
                          ><!-- <i class="fab fa-facebook-f"></i> --></a
                        >
                        <a :href="Belediyebilgiler.twit" target="_blank"
                          ><svg
                            class="svg-inline--fa fa-twitter fa-w-16"
                            aria-hidden="true"
                            data-prefix="fab"
                            data-icon="twitter"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                            ></path></svg
                          ><!-- <i class="fab fa-twitter"></i> --></a
                        >
                        <a :href="Belediyebilgiler.insta" target="_blank"
                          ><svg
                            class="svg-inline--fa fa-instagram fa-w-14"
                            aria-hidden="true"
                            data-prefix="fab"
                            data-icon="instagram"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                            ></path></svg
                          ><!-- <i class="fab fa-instagram"></i> --></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="contact-map box">
                <div id="map" class="contact-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3066.4992965559786!2d34.798261215709985!3d39.77334900243915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x408077ff19339421%3A0xc1dc46569e0a78c8!2zVE9HREVNIFlhesSxbMSxbQ!5e0!3m2!1str!2str!4v1615190560395!5m2!1str!2str"
                    width="100%"
                    height="450"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ======= İLETİŞİM ======= -->
    </main>
    <!-- ======= MAIN ======= -->
  </div>
</template>

<script>
import firebase from "firebase/app";
export default {
  name: "Iletisim",
  data() {
    return {
      adii: "",
      gidecekmail: "",
      mesaj: "",
      konu: "",
      Belediyebilgiler: {},
      mailKontrol: false,
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
  methods: {
    async yolla() {
      const yeniIDD = Date.now().toString();
      await firebase.firestore().collection("Mailler").doc(yeniIDD).set({
        adi: this.adii,
        gidecekmail: this.gidecekmail,
        mesaj: this.mesaj,
        konu: this.konu,
        domainId: this.$store.getters.getDomainId,
      });

      this.mailKontrol = true;
    },
  },
};
</script>

<style>
</style>